<template>
    <section class="text-gray-900 body-font w-full">
        <article class="hero-wrapper relative">
            <div class="absolute inset-0 bg-gray-300 corp-travel-hero-bg">
                <img src="../assets/HeaderBannerimages/13.png" class="hidden xl:block lg:w-full h-full"
                    style="filter: grayscale(.5) contrast(1.2) opacity(0.4);" />
            </div>
            <div class="container px-5 py-24 mx-auto flex">
                <div
                    class="lg:w-1/2 bg-white rounded-lg p-8 flex flex-col md:ml-auto w-full mt-10 md:mt-0 relative z-10 shadow-md">
                    <h1 class="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">
                        We just don’t say we go the <br class="hidden lg:inline-block" /> extra mile.
                        <!--, we <span
                            class="font-bold">live it
                            
                        </span>-->
                    </h1>
                    <p class="mb-8 leading-relaxed">
                        We understand every client is different. We focus on what the client needs and deliver on
                        each one every time. That’s the Assure Guarantee.
                    </p>
                    <div class="flex justify-center">
                        <router-link tag="button" to="/contact"
                            class="rounded-lg inline-flex text-white bg-purple-950 border-0 py-4 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg">
                            Contact an Assure Travel Representative
                        </router-link>
                    </div>
                </div>
            </div>
            <div class="custom-shape-divider-bottom-1624377600">
                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120"
                    preserveAspectRatio="none">
                    <path d="M741,116.23C291,117.43,0,27.57,0,6V120H1200V6C1200,27.93,1186.4,119.83,741,116.23Z"
                        class="shape-fill"></path>
                </svg>
            </div>
        </article>
        <article class="bg-purple-950 w-full lg:-mb-24">
            <div class="container py-24 mx-auto text-left relative block">
                <div class="text-center mb-20">
                    <h1 class="sm:text-5xl text-2xl font-medium text-center title-font text-gray-100 mb-4">
                        Assure Travel Advantage</h1>
                    <!-- <p class="text-xl leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto">Great salesy blurb can go
                    here about items this service offers.</p> -->
                </div>
                <div class="flex flex-wrap w-full sm:mx-auto sm:mb-2 -mx-2">
                    <div class="p-2 sm:w-1/2 lg:w-1/3 w-full">
                        <div class="bg-gray-100 rounded-sm flex p-4 h-full items-center">
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                stroke-width="3" class="text-purple-950 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
                                <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                                <path d="M22 4L12 14.01l-3-3"></path>
                            </svg>
                            <span class="title-font font-medium">Development of customized travel management goals and
                                objectives</span>
                        </div>
                    </div>
                    <div class="p-2 sm:w-1/2 lg:w-1/3 w-full">
                        <div class="bg-gray-100 rounded-sm flex p-4 h-full items-center">
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                stroke-width="3" class="text-purple-950 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
                                <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                                <path d="M22 4L12 14.01l-3-3"></path>
                            </svg>
                            <span class="title-font font-medium">Assistance with the creation or revision of an
                                effective
                                travel policy</span>
                        </div>
                    </div>
                    <div class="p-2 sm:w-1/2 lg:w-1/3 w-full">
                        <div class="bg-gray-100 rounded-sm flex p-4 h-full items-center">
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                stroke-width="3" class="text-purple-950 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
                                <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                                <path d="M22 4L12 14.01l-3-3"></path>
                            </svg>
                            <span class="title-font font-medium">Hassle-free full service travel management, with online or on-site
                                account
                                implementation</span>
                        </div>
                    </div>
                    <div class="p-2 sm:w-1/2 lg:w-1/3 w-full">
                        <div class="bg-gray-100 rounded-sm flex p-4 h-full items-center">
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                stroke-width="3" class="text-purple-950 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
                                <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                                <path d="M22 4L12 14.01l-3-3"></path>
                            </svg>
                            <span class="title-font font-medium">Dedicated account management from highly experienced
                                agents</span>
                        </div>
                    </div>
                    <div class="p-2 sm:w-1/2 lg:w-1/3 w-full">
                        <div class="bg-gray-100 rounded-sm flex p-4 h-full items-center">
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                stroke-width="3" class="text-purple-950 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
                                <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                                <path d="M22 4L12 14.01l-3-3"></path>
                            </svg>
                            <span class="title-font font-medium">Quality control measures to ensure corporate travel
                                policy
                                compliance</span>
                        </div>
                    </div>
                    <div class="p-2 sm:w-1/2 lg:w-1/3 w-full">
                        <div class="bg-gray-100 rounded-sm flex p-4 h-full items-center">
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                stroke-width="3" class="text-purple-950 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
                                <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                                <path d="M22 4L12 14.01l-3-3"></path>
                            </svg>
                            <span class="title-font font-medium">Benchmark reporting and analysis to identify cost
                                saving
                                opportunities</span>
                        </div>
                    </div>
                    <div class="p-2 sm:w-1/2 lg:w-1/3 w-full">
                        <div class="bg-gray-100 rounded-sm flex p-4 h-full items-center">
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                stroke-width="3" class="text-purple-950 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
                                <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                                <path d="M22 4L12 14.01l-3-3"></path>
                            </svg>
                            <span class="title-font font-medium">Advanced online booking tools</span>
                        </div>
                    </div>
                    <div class="p-2 sm:w-1/2 lg:w-1/3 w-full">
                        <div class="bg-gray-100 rounded-sm flex p-4 h-full items-center">
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                stroke-width="3" class="text-purple-950 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
                                <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                                <path d="M22 4L12 14.01l-3-3"></path>
                            </svg>
                            <span class="title-font font-medium">Customized, real-time travel information
                                reporting</span>
                        </div>
                    </div>
                    <div class="p-2 sm:w-1/2 lg:w-1/3 w-full">
                        <div class="bg-gray-100 rounded-sm flex p-4 h-full items-center">
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                stroke-width="3" class="text-purple-950 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
                                <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                                <path d="M22 4L12 14.01l-3-3"></path>
                            </svg>
                            <span class="title-font font-medium">Comprehensive Duty of Care and Emergency
                                Management</span>
                        </div>
                    </div>
                    <div class="p-2 sm:w-1/2 lg:w-1/3 w-full">
                        <div class="bg-gray-100 rounded-sm flex p-4 h-full items-center">
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                stroke-width="3" class="text-purple-950 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
                                <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                                <path d="M22 4L12 14.01l-3-3"></path>
                            </svg>
                            <span class="title-font font-medium">24/7 global traveler emergency assistance</span>
                        </div>
                    </div>
                    <div class="p-2 sm:w-1/2 lg:w-1/3 w-full">
                        <div class="bg-gray-100 rounded-sm flex p-4 h-full items-center">
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                stroke-width="3" class="text-purple-950 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
                                <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                                <path d="M22 4L12 14.01l-3-3"></path>
                            </svg>
                            <span class="title-font font-medium">Exclusive corporate hotel rate program</span>
                        </div>
                    </div>
                    <div class="p-2 sm:w-1/2 lg:w-1/3 w-full">
                        <div class="bg-gray-100 rounded-sm flex p-4 h-full items-center">
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                stroke-width="3" class="text-purple-950 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
                                <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                                <path d="M22 4L12 14.01l-3-3"></path>
                            </svg>
                            <span class="title-font font-medium">Hotel block space allotments with guaranteed last room
                                availability</span>
                        </div>
                    </div>
                    <div class="p-2 sm:w-1/2 lg:w-1/3 w-full">
                        <div class="bg-gray-100 rounded-sm flex p-4 h-full items-center">
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                stroke-width="3" class="text-purple-950 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
                                <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                                <path d="M22 4L12 14.01l-3-3"></path>
                            </svg>
                            <span class="title-font font-medium">Unused non-refundable ticket management</span>
                        </div>
                    </div>
                    <div class="p-2 sm:w-1/2 lg:w-1/3 w-full">
                        <div class="bg-gray-100 rounded-sm flex p-4 h-full items-center">
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                stroke-width="3" class="text-purple-950 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
                                <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                                <path d="M22 4L12 14.01l-3-3"></path>
                            </svg>
                            <span class="title-font font-medium">Pre-trip audits on all itineraries to optimize low fare
                                opportunities</span>
                        </div>
                    </div>
                    <div class="p-2 sm:w-1/2 lg:w-1/3 w-full">
                        <div class="bg-gray-100 rounded-sm flex p-4 h-full items-center">
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                stroke-width="3" class="text-purple-950 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
                                <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                                <path d="M22 4L12 14.01l-3-3"></path>
                            </svg>
                            <span class="title-font font-medium">Agency-negotiated car rental rates & airport parking
                                discounts</span>
                        </div>
                    </div>
                    <div class="p-2 sm:w-1/2 lg:w-1/3 w-full">
                        <div class="bg-gray-100 rounded-sm flex p-4 h-full items-center">
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                stroke-width="3" class="text-purple-950 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
                                <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                                <path d="M22 4L12 14.01l-3-3"></path>
                            </svg>
                            <span class="title-font font-medium">Executive VIP & Concierge Services</span>
                        </div>
                    </div>
                    <div class="p-2 sm:w-1/2 lg:w-1/3 w-full">
                        <div class="bg-gray-100 rounded-sm flex p-4 h-full items-center">
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                stroke-width="3" class="text-purple-950 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
                                <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                                <path d="M22 4L12 14.01l-3-3"></path>
                            </svg>
                            <span class="title-font font-medium">Full service Meetings & Incentives division</span>
                        </div>
                    </div>
                </div>
                <router-link to="/contact" tag="button"
                    class="flex mx-auto mt-16 text-white bg-yellow-950 border-0 py-4 px-8 focus:outline-none hover:bg-indigo-600 rounded-lg text-lg">
                    Speak
                    with Travel Consultant</router-link>
            </div>
        </article>
    </section>
</template>

<script>
    // @ is an alias to /src
    // import HelloWorld from "@/components/HelloWorld.vue";
    // import HeroSection from "@/components/HeroSection.vue";
    export default {
        name: "corporatetravel",
        components: {
            // HelloWorld,
        },
    };
</script>
<style scoped>
    .custom-shape-divider-bottom-1624377600 {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        overflow: hidden;
        line-height: 0;
        transform: rotateY(180deg);
    }

    .custom-shape-divider-bottom-1624377600 svg {
        position: relative;
        display: block;
        width: calc(185% + 1.3px);
        height: 75px;
        transform: rotateY(180deg);
    }

    .custom-shape-divider-bottom-1624377600 .shape-fill {
        fill: #1D156B;
    }

    .corp-travel-hero-bg {
        background-image: url('../assets/HeaderBannerimages/13.png');
        background-repeat: no-repeat;
        background-size: contain;
    }



    /* lg */
    @media(min-width:1024px) {}

    /* xl */
    @media(min-width:1280px) {
        .custom-shape-divider-bottom-1624377600 {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 103%;
            overflow: hidden;
            line-height: 0;
            transform: rotateY(180deg);
        }

        .custom-shape-divider-bottom-1624377600 svg {
            position: relative;
            display: block;
            width: calc(185% + 1.3px);
            height: 249px;
            transform: rotateY(180deg);
        }

        .corp-travel-hero-bg {
            background-image: none;
        }

        .hero-wrapper {
            min-height: 600px;
        }
    }
</style>